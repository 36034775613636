<template>
    <div v-if="!noAccount && !$auth.isAuthenticated && !skipAsk" class="text-center">
        Do you already have a Nuvolos account?
        <div class="text-center mt-4">
            <v-btn @click="loginAndRedirect" text class="mr-4">Yes</v-btn>
            <v-btn @click="setNoAccount" text>No</v-btn>
        </div>
    </div>
    <div v-else>
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            noAccount: sessionStorage.getItem('userHasNoAccount') === 'true'
        }
    },
    methods: {
        loginAndRedirect() {
            this.$router.push({ name: 'login', query: { targetUrl: location.pathname } })
        },
        setNoAccount() {
            window.sessionStorage.setItem('userHasNoAccount', true)
            this.noAccount = true
        }
    },
    props: {
        skipAsk: {
            type: Boolean,
            default: false
        }
    }
}
</script>
